import { IJapaneseReading } from '../../../../../../core/src'
import { JapaneseReadingEntity } from '../entities/japanese-reading'

export class JapaneseReadingEntityFactory {
  /**
   * Generates an IJapaneseReading object. Used to add extra properties during the conversion process.
   * @param props Data to use when creating the object.
   */
  defaultProps(props?: IJapaneseReading): IJapaneseReading {
    return { id: 0, i18n: [], ...props }
  }

  /**
   * Generates a JapaneseReadingEntity object.
   * @param props Data to use when creating the object.
   */
  new(props?: IJapaneseReading): JapaneseReadingEntity {
    return new JapaneseReadingEntity(this.defaultProps(props))
  }

  /**
   * Shorthand version to create an entity containing only a value and a label.
   * @param value
   * @param label
   * @returns
   */
  newFromValueLabel(value: number, label: string): JapaneseReadingEntity {
    return new JapaneseReadingEntity(
      this.defaultProps({
        id: value,
        i18n: [{ name: label, description: '', locale: '' }],
      })
    )
  }
}
