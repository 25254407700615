import { GatewayBase } from '@/base/gateways/base'
import { CandidateSex, ICandidate, ICandidateDashboardStats, ICandidateVisa, IUser, LanguageLocale } from '@tokyojob/core'
import {
  CandidateDashboardStats,
  CandidateDtoTranslator,
  FullCandidateUserProfile,
  UpdateCandidateJapaneseLanguageSkills,
  UpdateCandidatePersonalInformation,
  UpdateCandidateVisa
} from '@tokyojob/frontend-core'
import CandidateVisaEntity, { ICandidateVisaProps } from '../entities/candidate.visa'
import UserEntity from '../entities/user'
import { GetCurrentICandidateVisaRequest, GetCurrentVisaRequest, UpdateCandidateProfile } from '../network/requests/candidate'
import { toCandidateVisaProps, toCandidateVisaSeed } from './translator/candidate'

export default class Candidate extends GatewayBase {
  /**
   * Update current candidate's profile.
   * @returns Promise<ICandidateVisaProps | null>
   */
  async UpdateProfile(userEntity: UserEntity, entity: CandidateVisaEntity): Promise<ICandidateVisaProps | null> {
    const seed = toCandidateVisaSeed(userEntity, entity)
    const item = await this.apiClient.request(new UpdateCandidateProfile(seed))
    return item ? toCandidateVisaProps(item) : null
  }

  /**
   * gets current candidate visa profile and translate it into CandidateVisaPros
   * @returns Promise<ICandidateVisaProps | null>
   */
  async getCurrent(): Promise<ICandidateVisaProps | null> {
    const item = await this.apiClient.request(new GetCurrentVisaRequest(LanguageLocale.USen))
    return item ? toCandidateVisaProps(item) : null
  }

  /**
   * Gets the current candidate ICandidateVisa data.
   * @returns
   */
  async getCurrentICandidateVisa(): Promise<ICandidateVisa | null> {
    const item = await this.apiClient.request(new GetCurrentICandidateVisaRequest(LanguageLocale.USen))
    return item
  }

  /**
   * Update current candidate's name.
   * @remarks Used by the new Onboarding Process.
   */
  async updateVisa(candidateVisa: ICandidateVisa): Promise<boolean> {
    const translator = new CandidateDtoTranslator()
    const dto = translator.candidateVisaToDto(candidateVisa)
    if (!dto.visaTypeId) return false
    const item = await this.apiClient.request(new UpdateCandidateVisa(dto))
    return item
  }

  /**
   * Update current candidate's name.
   * @remarks Used by the new Onboarding Process.
   */
  async updatePersonalInformation(user: IUser): Promise<boolean> {
    const { gender, candidate } = user
    if (!candidate) return false
    const selectedGender = gender as CandidateSex
    const { birthDate, country, firstArrivalDate, grewUpInCountry, prefecture, prefectureId, city, cityId } = candidate
    // Move the validation checks to a specification / validation object.
    if (!gender || !birthDate) return false
    const newPrefectureId = prefecture?.id || prefectureId
    const newCityId = city?.id || cityId
    if (!newPrefectureId || !newCityId) return false

    const item = await this.apiClient.request(
      new UpdateCandidatePersonalInformation({
        birthDate: birthDate,
        gender: selectedGender,
        countryId: country?.id,
        firstArrivalDate,
        grewUpInCountryId: grewUpInCountry?.id,
        prefectureId: newPrefectureId,
        cityId: newCityId
      })
    )
    return item
  }

  /**
   * Update current candidate's name.
   * @remarks Used by the new Onboarding Process.
   */
  async updateJapaneseLanguageSkills(candidate: ICandidate): Promise<boolean> {
    if (!candidate) return false
    const { japaneseConversation, japaneseListening, japaneseReading, japaneseWriting } = candidate
    if (!japaneseListening) return false
    const item = await this.apiClient.request(
      new UpdateCandidateJapaneseLanguageSkills({
        japaneseListeningId: japaneseListening.id!,
        japaneseConversationId: japaneseConversation?.id,
        japaneseReadingId: japaneseReading?.id,
        japaneseWritingId: japaneseWriting?.id
      })
    )
    return item
  }

  /**
   * Fetches the current candidate's full user profile, including candidate related data.
   * @returns
   */
  async getFullUserProfile(locale: LanguageLocale = LanguageLocale.USen): Promise<IUser | null> {
    const item = await this.apiClient.request(new FullCandidateUserProfile(locale))
    return item ?? null
  }

  /**
   * Fetches the current candidate's dashboard stats.
   * @returns
   */
  async GetCandidateDashboardStatsUsecase(): Promise<ICandidateDashboardStats> {
    const item = await this.apiClient.request(new CandidateDashboardStats())
    return item
  }
}
