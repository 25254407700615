
import { CMSLinkFactory, IJoomlaSidemenuLink, LanguageURLToLocaleMap, LocalizationData, LocalizationPath } from '@tokyojob/core'
import {
  ButtonFactory,
  CButton,
  CIcon,
  CIconFactory,
  FilterQueryManager,
  getLocalization,
  IconSize,
  IconType,
  RouterLinkFactory
} from '@tokyojob/frontend-components'
import { AppEnvironment, LocalizationService, StaticURLBuilder } from '@tokyojob/frontend-core'
import { defineComponent, reactive, Ref, ref, watch } from 'vue'
import { CMSGetJoomlaLayoutContentUseCase } from '~/modules/cms/usecases/get-joomla-layout-content.usecase'
import CSidebarJoomlaMenuLink from './component/c-sidebar-joomla-menu-link.component.vue'
// import CSidebarJoomlaSearchInput from './c-joomla-search-input.component.vue'
import { IApp } from '~/types/nuxt'
import CSidebarCandidateMenu from './component/c-sidebar-candidate-menu.component.vue'
import CSidebarLanguageSwitcher from './component/c-sidebar-language-switcher.component.vue'
import CSidebarSocialLinks from './component/c-sidebar-social-links.component.vue'
import { getProvidedApp, getRoute } from '~/base/utils/provided-getter'
import { getSideMenuContentStore } from '~/store/side-menu.store'

const filterQueryManager = new FilterQueryManager()
const vacancyListURL = '/'

export default defineComponent({
  components: {
    CIcon,
    CButton,
    CSidebarJoomlaMenuLink,
    CSidebarSocialLinks,
    CSidebarLanguageSwitcher,
    CSidebarCandidateMenu
  },
  props: {
    showLanguageSwitcher: {
      type: Boolean,
      default: true
    }
  },
  setup(_, { emit }) {
    const app: IApp = getProvidedApp()
    const cookieRepository = reactive(app.state)
    const route = getRoute()
    const sidebarContainer: Ref<HTMLDivElement> = ref(null) as any
    const localization: LocalizationData = getLocalization(app)
    const query = filterQueryManager.cookieToQueryString(app, '')
    const logoLink = ref(`${vacancyListURL}${query}`)
    const isVacancyList = ref(route.value.path === vacancyListURL)
    const sidebarAppLinksTitle = ref('')
    const sidebarJoomlaLinksTitle = ref('')
    const sidebarOptionsTitle = ref('')
    const sidebarJoomlaLinks = ref<IJoomlaSidemenuLink[]>([])
    const mapLink = RouterLinkFactory.localizedLink('/job/map', app.localizationSelector.language)
    const loginLink = RouterLinkFactory.localizedLink('/auth/login', app.localizationSelector.language)
    const agentDashboardLink = RouterLinkFactory.localizedLink('/agent', app.localizationSelector.language)
    const gridLink = RouterLinkFactory.localizedLink('/', app.localizationSelector.language)
    const allJobs = ref(LocalizationService.t<string>(localization, LocalizationPath.allJobs))
    const jobsInMyArea = ref(LocalizationService.t<string>(localization, LocalizationPath.jobsInMyArea))
    const homeIcon = CIconFactory.Icon({ icon: 'briefcase', size: IconSize.size16 })
    const locationIcon = CIconFactory.Icon({ icon: 'map-location-dot', size: IconSize.size16 })
    const appLinks = [
      { path: gridLink, icon: homeIcon, label: allJobs.value },
      { path: mapLink, icon: locationIcon, label: jobsInMyArea.value }
    ]

    //Buttons and Icons
    const logoutBtn = ButtonFactory.button({
      label: LocalizationService.t<string>(localization, LocalizationPath.logout),
      icon: CIconFactory.Icon({ icon: 'right-from-bracket', type: IconType.light, size: IconSize.medium }),
      classes: 'row-center-fullwidth align-center g-10 h-36 theme-blue-dark1-color text-small ph-10 pv-5 br-7'
    })
    const loginBtn = ButtonFactory.button({
      link: { link: loginLink },
      icon: CIconFactory.Icon({ icon: 'right-to-bracket', type: IconType.light, size: IconSize.medium }),
      label: LocalizationService.t<string>(localization, LocalizationPath.login),
      classes: 'row-center-fullwidth align-center g-10 h-36 theme-blue-dark1-color text-small ph-10 pv-5 br-7'
    })
    const agentDashboardBtn = ButtonFactory.button({
      link: { link: agentDashboardLink },
      icon: CIconFactory.Icon({ icon: 'gauge-high', type: IconType.light, size: IconSize.medium }),
      label: LocalizationService.t<string>(localization, LocalizationPath.goToAgentDashboardText),
      classes: 'row-center-fullwidth align-center g-10 theme-blue-dark1-color text-xsmall ph-10 pv-5 br-7'
    })
    /**
     * Refreshes the side menus from joomla
     */
    const joomlaSidemenuRefresh = () => {
      const sideMenuContentStore = getSideMenuContentStore()
      const locale = app.localizationSelector.languageLocale
      const joomlaLayoutContent = sideMenuContentStore.get(locale)
      sidebarAppLinksTitle.value = CMSLinkFactory.getAppSectionTitle(joomlaLayoutContent)
      sidebarJoomlaLinksTitle.value = CMSLinkFactory.getJoomlaSectionTitle(joomlaLayoutContent)
      sidebarOptionsTitle.value = CMSLinkFactory.getOptionsTitle(joomlaLayoutContent)
      sidebarJoomlaLinks.value = CMSLinkFactory.getJoomlaLinks(joomlaLayoutContent)
    }
    joomlaSidemenuRefresh()
    // METHODS
    /**
     * Log user out. Return user to agent login page.
     */
    function executeLogout() {
      app.state.token = ''
      app.state.refreshToken = ''
      app.state.guestToken = ''
      window.location.href = '/auth/login'
    }
    function refreshLoginLink() {
      const query = filterQueryManager.cookieToQueryString(app, '')
      logoLink.value = `${vacancyListURL}${query}`
    }
    function toggleMenu() {
      emit('toggleMenu')
    }
    /**
     * Smooth scroll to bottom of container.
     */
    function scrollBottom() {
      const sidebar = sidebarContainer.value
      setTimeout(function() {
        sidebarContainer.value.scrollTo({
          top: sidebar.scrollHeight,
          behavior: 'smooth'
        })
      }, 1)
    }

    watch(
      () => route.value.path,
      (newPath) => {
        isVacancyList.value = newPath === vacancyListURL

        allJobs.value = LocalizationService.t<string>(localization, LocalizationPath.allJobs)
        jobsInMyArea.value = LocalizationService.t<string>(localization, LocalizationPath.jobsInMyArea)

        refreshLoginLink()

        // In SPA, whenever we update the URL we refresh the joomla side menu
        joomlaSidemenuRefresh()
      }
    )

    watch(
      () => route.value.query,
      (newQuery) => {
        refreshLoginLink()
      }
    )

    const isLoggedInAgent = ref(cookieRepository.isLoggedInAgent)
    watch(
      () => cookieRepository.isLoggedInAgent,
      (value) => (isLoggedInAgent.value = value)
    )

    const isLoggedIn = ref(cookieRepository.isLoggedIn || !!cookieRepository.guestToken)
    watch(
      () => cookieRepository.isLoggedIn,
      (value) => (isLoggedIn.value = value || !!cookieRepository.guestToken)
    )
    watch(
      () => cookieRepository.guestToken,
      (value) => (isLoggedIn.value = cookieRepository.isLoggedIn || !!value)
    )

    return {
      //Data
      allJobs,
      jobsInMyArea,
      // Template Refs
      sidebarContainer,
      // Booleans
      isVacancyList,
      isLoggedInAgent,
      isLoggedIn,
      // Text
      sidebarAppLinksTitle,
      sidebarJoomlaLinksTitle,
      sidebarOptionsTitle,
      // Methods
      toggleMenu,
      scrollBottom,
      executeLogout,
      // Links
      vacancyListURL: vacancyListURL,
      logoLink,
      sidebarJoomlaLinks,
      appLinks,
      // Buttons and Icons
      logo: StaticURLBuilder.logo(process.env.APP_ENV as AppEnvironment),
      agentDashboardBtn,
      loginBtn,
      logoutBtn,
      homeIcon,
      locationIcon,
      route
    }
  }
})
