import { IJapaneseWriting } from '../../../../../../core/src'
import { IFormInputData } from '../../html/forms/inputs/interfaces/form-input-data'

/**
 * Entity containing a writing skill object.
 * TODO: Implement proper i18n for all translatable props
 */
export class JapaneseWritingEntity implements IFormInputData {
  private _props: IJapaneseWriting

  constructor(props: IJapaneseWriting) {
    this._props = props
  }

  /**
   * Raw properties of the entity. Used in cases when the data provided by the entity's getters and setters are insufficient.
   * For most cases, consider adding another getter / setter instead of interacting directly with the props.
   */
  get props(): IJapaneseWriting {
    return this._props
  }

  /**
   * The id of the writing  skill.
   */
  get id(): number {
    return this.props.id!
  }

  /**
   * The name of the writing  skill.
   */
  get name(): string {
    return this.props.i18n![0].name!
  }

  /**
   * Explains what this item is.
   */
  get description(): string | null {
    return this.props.i18n![0].description || null
  }

  /**
   * Label text to represent this item in the UI.
   */
  get label(): string {
    return this.name
  }

  /**
   * Internal value to keep track of this item when using it in the UI.
   */
  get value(): number {
    return this.id
  }
}
