import { IsArray, IsNumber } from 'class-validator';

/**
 * The DTO that validates the japanese langauge section
 */
export class VacancySaveJapaneseLanguageDto {
  /**
   * The ids of the japanese reading that is required in the vacancy
   */
  @IsNumber()
  japaneseReadings: number;

  /**
   * The ids of the japanese writing that is required in the vacancy
   */
  @IsNumber()
  japaneseWritings: number;

  /**
   * The ids of the japanese listening that is required in the vacancy
   */
  @IsNumber()
  japaneseListenings: number;

  /**
   * The ids of the japanese conversation that is required in the vacancy
   */
  @IsNumber()
  japaneseConversations: number;
}
