import { Type } from 'class-transformer';
import { IsDateString, IsDefined, IsNotEmptyObject, IsNumber, IsObject, IsOptional, ValidateNested } from 'class-validator';
import { CandidateVisaTypeDto } from '../../../candidate-visa/dto/candidate-visa-type.dto';

export class CandidateUpdateDto {
  /**
   * The unique identifier of the japanese listening record of this this.
   */
  @IsNumber()
  japaneseListeningId: number;

  /**
   * The unique identifier of the japanese reading record of this candidate
   */
  @IsNumber()
  japaneseReadingId: number;

  /**
   * The unique identifier of the japanese conversation record of this candidate
   */
  @IsNumber()
  japaneseConversationId: number;

  /**
   * The unique identifier of the japanese writing record of this candidate
   */
  @IsNumber()
  japaneseWritingId: number;

  /**
   * The birth date of the this.
   */
  @IsDateString()
  birthDate: Date;

  /**
   * The unique identifier of the country of citizenship of the this.
   */
  @IsNumber()
  @IsOptional()
  countryId?: number;

  /**
   * The country where the candidate grew up, applies to candidates with Japanese Citizenship only.
   */
  @IsNumber()
  @IsOptional()
  grewUpInCountryId?: number;

  /**
   * When did the candidate arrive in Japan.
   * If the candidate is a Japanese citizen and grew up in Japan this should be undefined.
   */
  @IsDateString()
  @IsOptional()
  firstArrivalDate?: Date;

  /**
   * The id of the prefecture where the candidate lives.
   */
  @IsNumber()
  prefectureId: number;

  /**
   * The id of the city where the candidate lives.
   */
  @IsNumber()
  cityId: number;

  /**
   * The candidate visa information
   */
  @IsDefined()
  @IsNotEmptyObject()
  @IsObject()
  @ValidateNested()
  @Type(() => CandidateVisaTypeDto)
  candidateVisa: CandidateVisaTypeDto;
}
