/**
 * The numerical value of each onboarding step
 */
export enum OnboardingStep {
  Welcome = 1,
  Name = 2,
  VisaType = 3,
  Contact = 4,
  PersonalInformation = 5,
  JapaneseLanguage = 6,
  ApplicationConfirmation = 7,
  JobStatus = 10
}
