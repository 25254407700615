/* eslint-disable no-console */
import BaseGateway from '@/base/gateways'
import { Context } from '@nuxt/types/app'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import * as Sentry from '@sentry/vue'
import { FilterQueryManager, FilterTagFactory } from '@tokyojob/frontend-components'
import {
  getLocalizationStore,
  JWTHandler,
  LocalizationSelector,
  LocalizationService,
  LogService,
  APIClient,
  AppEnvironment
} from '@tokyojob/frontend-core'
import Vue from 'vue'
import { SelectedVacancyFiltersDomain } from '~/modules/app/components/c-filter/util/selected-vacancy-filters-domain'
import { CookieRepository } from '~/modules/app/repositories/cookie.repository'
import { GetCandidateApplicationIdAndVacancyIdListUsecase } from '~/modules/application/usecases/get-candidate-application-id-and-vacancy-id-list.usecase'
import { CandidateFullProfileDomain } from '~/modules/user/candidate-full-profile-domain'
import { CandidateFullProfileUsecase } from '~/modules/user/usecases/candidate-full-profile.usecase'
import LocaleUpdateUseCase from '~/modules/user/usecases/locale-update.usecase'
import SaveUseCase from '~/modules/user/usecases/save.usecase'
import { getVacancyTreeFilterData } from '@tokyojob/frontend-components'
import { IApp } from '~/types/nuxt'
import { i18n } from '@tokyojob/frontend-core'
import { useResumeStore } from '~/modules/resume/store/resume.store'

/**
 * Injects the required dependencies to be used throughout the application.
 * Includes API services, Logging services, etc.
 */
export default defineNuxtPlugin(async (ctx: Context) => {
  const { app, route } = ctx

  if (process.client && process.env.APP_ENV !== AppEnvironment.Development && !process.env.sentryInitialized) {
    // Setup sentry in the app
    Sentry.init({
      app: Vue,
      dsn: 'https://43a703774aee70b4bdb3b729c763b072@o4507008132382720.ingest.us.sentry.io/4507013830475776',
      environment: process.env.APP_ENV,
      integrations: [
        Sentry.browserTracingIntegration()
        //  Sentry.replayIntegration({ maskAllText: false, blockAllMedia: true })
      ],
      // Performance Monitoring
      tracesSampleRate: 0.2, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/(?:test\.)?peregreworks\.jp\/api/],
      // Session Replay
      // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // set it to 0 in dev
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
    process.env.sentryInitialized = 'true'
  }
  const logger = {
    captureException(params: Error | string) {
      console.log(params)
      if (process.env.APP_ENV !== AppEnvironment.Development) Sentry.captureException(params)
    }
  }

  const state = new CookieRepository(app.$cookies)
  const baseGateway = new BaseGateway(new APIClient(state))
  const logService = new LogService(logger)

  LocalizationService.logService = logService
  const language = LocalizationService.getLanguage(route)
  const localizationSelector = new LocalizationSelector(
    (language) => {
      state.language = language
    },
    logService,
    language
  )

  const App: IApp = {
    state,
    baseGateway,
    logService,
    localizationSelector,
    lang: language
  }

  const vacancyTreeFilterDataStore = getVacancyTreeFilterData()
  const candidateFullProfileUsecase = new CandidateFullProfileUsecase(App)
  const saveUsecase = new SaveUseCase(App)
  const getCandidateApplicationIdAndVacancyIdListUsecase = new GetCandidateApplicationIdAndVacancyIdListUsecase(App)
  const localeUpdateUsecase = new LocaleUpdateUseCase(App)
  const apiClient = new APIClient(App.state)
  const jwtHandler = new JWTHandler(apiClient)
  const filterQueryManager = new FilterQueryManager()
  const selectedVacancyFiltersDomain = new SelectedVacancyFiltersDomain(
    filterQueryManager,
    new FilterTagFactory(),
    vacancyTreeFilterDataStore.all()
  )
  App.candidateFullProfileDomain = new CandidateFullProfileDomain(
    App,
    candidateFullProfileUsecase,
    saveUsecase,
    getCandidateApplicationIdAndVacancyIdListUsecase,
    jwtHandler,
    filterQueryManager,
    selectedVacancyFiltersDomain,
    localeUpdateUsecase
  )

  /**
   * We set the request needed to be called when changing language
   */
  localizationSelector.setLanguageUpdateRequest(async (locale) => {
    await App.candidateFullProfileDomain?.updateLocale(locale)
  })

  // If the app is not is ssr mode, we initialize the language data in the cookie
  if (!process.server) {
    state.language = language
  }

  const localization = getLocalizationStore()
  localization.set(i18n as any)

  // initialize resume store to avoid timing issues
  useResumeStore()

  Vue.prototype.App = App
  ctx.App = App
})
