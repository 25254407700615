/**
 * The Library component which holds all icons we currently use.
 * @remarks Please see NuxtIconsInitializer class below for a more thorough explanation.
 */
import { library } from '@fortawesome/fontawesome-svg-core'
/**
 * Import Icons from **BRAND** Pack
 */
import {
  faFacebookMessenger,
  faGoogle,
  faInstagram,
  faLine,
  faViber,
  faLinkedin,
  faWhatsappSquare,
  faFacebook,
  faYoutube,
  faApple,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
/**
 * Import Icons from **DUOTONE** Pack
 */
import {
  faAnglesLeft as fadAnglesLeft,
  faAnglesRight as fadAnglesRight,
  faBriefcase as fadBriefcase,
  faEnvelope as fadEnvelope,
  faMapLocationDot as fadMapLocationDot,
  faMobile as fadMobile,
  faYenSign as fadYenSign,
  faSpinnerThird as fadSpinnerThird,
  faFileCircleQuestion
} from '@fortawesome/pro-duotone-svg-icons'

/**
 * Import Icons from **REGULAR** Pack
 */
import {
  faSquare as farSquare,
  faRightToBracket as farRightToBracket,
  faRightFromBracket as farRightFromBracket,
  faMagnifyingGlass as farMagnifyingGlass,
  faCircleXmark as farCircleXmark,
  faCircleQuestion as farCircleQuestion
} from '@fortawesome/pro-regular-svg-icons'

/**
 * Import Icons from **LIGHT** Pack
 */
import {
  faGlobe as falGlobe,
  faRightToBracket as falRightToBracket,
  faRightFromBracket as falRightFromBracket,
  faGaugeHigh as falGaugeHigh,
  faCircleCheck as falCircleCheck
} from '@fortawesome/pro-light-svg-icons'

/**
 * Import Icons from **THIN** Pack
 */
import { faChevronRight as fatChevronRight } from '@fortawesome/pro-thin-svg-icons'
/**
 * Import Icons we have uploaded to **KIT UPLOADS** Pack
 */
// import { faVisibleInternet } from '@fortawesome/pro-solid-svg-icons'

/**
 * Import Icons from **SOLID** Pack here.
 * @remarks , When used in other packs as well we add "fas" + icon name in order to differentiate between packs.
 * @example  faBriefcase as fasBriefcase,
 */
import {
  faBoxArchive,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faArrowUpRightFromSquare,
  faArrowUpFromLine,
  faAsterisk,
  faBars,
  faBell,
  faBellOn,
  faBellSlash,
  faBinoculars,
  faBookmark,
  faBriefcase as fasBriefcase,
  faBuilding,
  faCalendarCheck,
  faCalendarDay,
  faCalendarDays,
  faCalendarEdit,
  faCalendarExclamation,
  faBusinessTime,
  faCalendarTimes,
  faCalendarWeek,
  faCamera,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp,
  faCircle,
  faCircleArrowDown,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleCheck,
  faCircleEnvelope,
  faCircleMinus,
  faCirclePlus,
  faCircleUser,
  faCircleXmark as fasCircleXmark,
  faClock,
  faCloudArrowUp,
  faCommentsAlt,
  faCropSimple,
  faDotCircle,
  faDown,
  faDownload,
  faEnvelope as fasEnvelope,
  faEllipsis,
  faEye,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePlus,
  faFileTimes,
  faFileUser,
  faFileWord,
  faFlag,
  faHandshake,
  faHandBackPointLeft,
  faHandBackPointUp,
  faHouse,
  faIdCard,
  faImage,
  faImageSlash,
  faKey,
  faLanguage,
  faLeft,
  faLocationCrosshairs,
  faLongArrowDown,
  faLongArrowLeft,
  faLockKeyhole,
  faLongArrowRight,
  faLongArrowUp,
  faMessages,
  faMessage,
  faMessageSms,
  faRotateLeft,
  faRotateRight,
  faMagnifyingGlass as fasMagnifyingGlass,
  faMapLocationDot as fasMapLocationDot,
  faMessageLines,
  faMobile as fasMobile,
  faPaperPlaneTop,
  faPen,
  faPersonThroughWindow,
  faPhoneArrowDownLeft,
  faPlay,
  faRectangleList,
  faRight,
  faShareNodes,
  faShieldCheck,
  faRightFromBracket,
  faSliders,
  faSquareCheck,
  faStar,
  faTimes,
  faTrain,
  faTrashCan,
  faUp,
  faUpload,
  faUser,
  faUserFriends,
  faYenSign as fasYenSign,
  faMapLocationDot,
  faCircleExclamation,
  faSitemap,
  faCopy,
  faSeedling,
  faFileCircleQuestion as fasFileCircleQuestion,
  faFileMagnifyingGlass,
  faCircleQuestion,
  faPhone,
  faPhoneMissed,
  faPhoneVolume,
  faPersonRays,
  faPersonCircleCheck,
  faPersonCircleXmark,
  faPersonArrowDownToLine,
  faUserLargeSlash,
  faPhoneSlash,
  faPersonCircleQuestion,
  faCalendarPen,
  faCalendarStar,
  faCalendar,
  faCalendarLinesPen,
  faGhost,
  faCalendars,
  faCalendarPlus,
  faHandHoldingDollar,
  faUserMagnifyingGlass,
  faThumbsDown,
  faPhoneArrowUpRight,
  faHandshakeSimple,
  faFilePen,
  faFileCheck,
  faStars,
  faHand
} from '@fortawesome/pro-solid-svg-icons'

/**
 * Initializes the Library
 * @remarks These methods do not need be seperated. However, I have done so in order to make FontAwesome import process more understandable.
 */
export class NuxtIconsInitializer {
  /**
   * Add all imported icons into Library.
   */
  init(): void {
    this.loadDefault()
    this.loadSolid()
    this.loadRegular()
    this.loadLight()
    this.loadThin()
    this.loadDuotone()
    this.loadBrands()
  }

  /**
   * Add Icons we are ONLY using in a **SOLID** style in the project.
   * We can do this as <Font-Awesome-Icon> in template defaults to fas prefix.
   * If one of the icons here becomes used in another format aswell e.g. 'duotone'
   *    please delete from here and add the specifc variation in 'loadSolid()' below, as well as in 'loadDuotone()'
   *    e.g. Delete faCamera from 'loadDefault()'. Add fasCamera to 'loadSolid()'. Add fadCamera to 'loadDuotone()'.
   *
   */
  loadDefault() {
    library.add(
      faArrowLeft,
      faArrowRight,
      faArrowUp,
      faArrowDown,
      faArrowRightFromBracket,
      faArrowRightToBracket,
      faRightFromBracket,
      faArrowUpRightFromSquare,
      faArrowUpFromLine,
      faBars,
      faBell,
      faBellOn,
      faBellSlash,
      faBinoculars,
      faCheck,
      faCircle,
      faCircleCheck,
      faCircleEnvelope,
      faCircleArrowUp,
      faCircleArrowDown,
      faCircleArrowLeft,
      faCircleArrowRight,
      faCircleMinus,
      faCirclePlus,
      faCloudArrowUp,
      faEllipsis,
      faEye,
      faPen,
      faPersonThroughWindow,
      faBusinessTime,
      faTrashCan,
      faCopy,
      faFileCircleQuestion,
      faSeedling,
      faFileMagnifyingGlass,
      faCircleQuestion,
      faPhone,
      faPhoneMissed,
      faPhoneVolume,
      faPersonRays,
      faPersonCircleCheck,
      faPersonCircleXmark,
      faPersonArrowDownToLine,
      faUserLargeSlash,
      faPhoneSlash,
      faPersonCircleQuestion,
      faCalendarPen,
      faCalendarStar,
      faCalendar,
      faCalendarLinesPen,
      faGhost,
      faCalendars,
      faCalendarPlus,
      faHandHoldingDollar,
      faUserMagnifyingGlass,
      faThumbsDown,
      faPhoneArrowUpRight,
      faHandshakeSimple,
      faFilePen,
      faFileCheck,
      faStars,
      faMessages,
      faMessage,
      faHand,
      faCropSimple,
      faCamera,
      faBookmark,
      faKey,
      faShareNodes,
      faPaperPlaneTop,
      faPlay,
      faPhoneArrowDownLeft,
      faDownload,
      faCaretUp,
      faCaretDown,
      faCaretLeft,
      faCaretRight,
      faLanguage,
      faLongArrowUp,
      faLongArrowDown,
      faLongArrowLeft,
      faLongArrowRight,
      faLocationCrosshairs,
      faUp,
      faUpload,
      faDown,
      faLeft,
      faRight,
      faRotateLeft,
      faRotateRight,
      faChevronUp,
      faCircleUser,
      faChevronDown,
      faChevronLeft,
      faChevronDoubleUp,
      faChevronDoubleDown,
      faChevronDoubleLeft,
      faChevronDoubleRight,
      faSquareCheck,
      faDotCircle,
      faAsterisk,
      faFileLines,
      faFilePdf,
      faFilePlus,
      faFileWord,
      faFileExcel,
      faFileUser,
      faFileImage,
      faImage,
      faImageSlash,
      faFlag,
      faHouse,
      faHandshake,
      faLockKeyhole,
      faTrain,
      faIdCard,
      faRectangleList,
      faShieldCheck,
      faBoxArchive,
      faMessageSms,
      faFileTimes,
      faStar,
      faBusinessTime,
      faCommentsAlt,
      faMessageLines,
      faSliders,
      faTimes,
      faUser,
      faUserFriends,
      faClock,
      faHandBackPointLeft,
      faHandBackPointUp,
      faBuilding,
      faCalendarDays,
      faCalendarTimes,
      faCalendarEdit,
      faCalendarWeek,
      faCalendarDay,
      faCalendarExclamation,
      faCalendarCheck,
      faMapLocationDot,
      faCircleExclamation,
      faSitemap
    )
  }

  /**
   * Add Icons we are using from **SOLID** style AND another style. In this case we must specify fas + icon name, as well as fad + icon name explicitly.
   */
  loadSolid() {
    library.add(
      fasBriefcase,
      fasMagnifyingGlass,
      fasMapLocationDot,
      fasYenSign,
      fasMobile,
      fasEnvelope,
      fasChevronRight,
      fasCircleXmark,
      fasFileCircleQuestion
    )
  }
  /**
   * Add Icons we are using from **REGULAR** style
   */
  loadRegular() {
    library.add(farSquare, farMagnifyingGlass, farCircleXmark, farRightToBracket, farRightFromBracket, farCircleQuestion)
  }
  /**
   * Add Icons we are using from **LIGHT** style
   */
  loadLight() {
    library.add(falGlobe, falRightToBracket, falRightFromBracket, falGaugeHigh, falCircleCheck)
  }
  /**
   * Add Icons we are using from **THIN** style
   */
  loadThin() {
    library.add(fatChevronRight)
  }
  /**
   * Add Icons we are using from **DUOTONE** style
   */
  loadDuotone() {
    library.add(
      fadBriefcase,
      fadMapLocationDot,
      fadYenSign,
      fadMobile,
      fadEnvelope,
      fadSpinnerThird,
      fadAnglesLeft,
      fadAnglesRight
    )
  }
  /**
   * Add Icons we are using from **BRANDS** style
   */
  loadBrands() {
    library.add(
      faLine,
      faViber,
      faLinkedin,
      faWhatsappSquare,
      faFacebookMessenger,
      faGoogle,
      faFacebook,
      faYoutube,
      faInstagram,
      faApple,
      faTwitter
    )
  }
}
