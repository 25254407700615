import { IApp } from '@/types/nuxt'
import { LogType } from '@tokyojob/frontend-core'
import { getAppStore } from '~/store/app.store'

export default class MeUseCase implements BaseUsecase {
  app: IApp
  constructor(app: IApp) {
    this.app = app
  }

  async execute() {
    const { state, baseGateway, logService } = this.app
    const appStore = getAppStore()
    try {
      const res = await baseGateway.user.Me()
      appStore.setProfile(res!)
      return true
    } catch (error) {
      await logService.handle({ type: LogType.Error, error: error as Error })
      return false
    }
  }
}
