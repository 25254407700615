import { IJapaneseWriting } from '../../../../../../core/src'
import { JapaneseWritingEntity } from '../entities/japanese-writing'

export class JapaneseWritingEntityFactory {
  /**
   * Generates an IJapaneseWriting object. Used to add extra properties during the conversion process.
   * @param props Data to use when creating the object.
   */
  defaultProps(props?: IJapaneseWriting): IJapaneseWriting {
    return { id: 0, i18n: [], ...props }
  }

  /**
   * Generates a JapaneseWritingEntity object.
   * @param props Data to use when creating the object.
   */
  new(props?: IJapaneseWriting): JapaneseWritingEntity {
    return new JapaneseWritingEntity(this.defaultProps(props))
  }

  /**
   * Shorthand version to create an entity containing only a value and a label.
   * @param value 
   * @param label 
   * @returns 
   */
  newFromValueLabel(value: number, label: string): JapaneseWritingEntity {
    return new JapaneseWritingEntity(
      this.defaultProps({
        id: value,
        i18n: [{ name: label, description: '', locale: '' }],
      })
    )
  }
}
