import VueRouter, { Route } from 'vue-router'
import { Dictionary, FilterKey, IDNameSeparator, IDSeparator, IFilterableDataSetItem } from '../../../../../../../../core/src'
import { RouterReplaceHelper } from './router/router-replace-helper'

/**
 * This class is responsible for any logic involving the manipulation of URL query.
 */
export class FilterQueryManager {
  constructor(private readonly routerReplaceHelper = new RouterReplaceHelper()) {}

  /**
   * Initializes the URL query of the current page.
   * This reads the query from the URL.
   * Checks if there's no filter available in the URL
   * If none, checks if there's a saved filter in the cookies and applies it to the URL
   * Had to wait for the application to be mounted before we can use the application router
   * @param App
   * @param router
   * @param route
   */
  initialize(App: any, router: VueRouter, route: Route): Dictionary<string | (string | null)[]> {
    let query: Dictionary<string | (string | null)[]> = {}
    const exists = this.anyFilterExists(route.query)
    if (exists) {
      query = this.extractFilters(route.query)
      App.state.formattedFilters = JSON.stringify(query)
    } else {
      const strQueries = App.state.formattedFilters
      if (strQueries && strQueries != '') {
        const savedQueries = JSON.parse(strQueries)
        query = { ...route.query, ...savedQueries }
        this.routerReplaceHelper.replace(query, App, router, route)
      }
    }
    return query
  }

  /**
   * Modifies the current filter query
   * @param App
   * @param router
   * @param route
   * @param filterKey
   * @param item
   * @param hasPossibleIdPrefix
   */
  modifyFilter(filterKey: FilterKey, item: IFilterableDataSetItem, App: any, router?: VueRouter, route?: Route): void {
    const formattedID = item.path ? `${item.path}${IDSeparator}${item.id}` : item.id.toString()
    let query: any = {}
    if (route) query = { ...route.query }
    else if (App.state.formattedFilters && App.state.formattedFilters != '') query = JSON.parse(App.state.formattedFilters)
    query[filterKey] = `${formattedID}${IDNameSeparator}${item.slug}`
    this.modifyQuery(query, App, router, route)
  }

  /**
   * @todo Move ISelectedFilterMultiOptions to the components library and use it instead of the contractTypeId.
   * @param App
   * @param router
   * @param route
   * @param contractTypeId
   */
  modifyMultiOptionFilter(App: any, router: VueRouter, route: Route, contractTypeId: string): void {
    const query = { ...route.query }
    query[FilterKey.contract] = `${contractTypeId}`
    this.modifyQuery(query, App, router, route)
  }

  /**
   * Updates the current query in the URL
   * @param App
   * @param router
   * @param route
   * @param query
   */
  modifyQuery(
    query: Dictionary<string | (string | null)[]>,
    App: any,
    router?: VueRouter,
    route?: Route,
    additionalQuery: Dictionary<string | (string | null)[]> = {}
  ): void {
    this.routerReplaceHelper.replace(query, App, router, route, additionalQuery)
  }

  /**
   * Extracts the filter query from the passed object
   * @param query
   * @returns
   */
  extractFilters(query: Dictionary<string | (string | null)[]>): Dictionary<string | (string | null)[]> {
    const extractedQuery: Dictionary<string | (string | null)[]> = {}
    for (const filterKey in FilterKey) {
      if (!!query[filterKey] && query[filterKey] != '') {
        extractedQuery[filterKey] = query[filterKey]
      }
    }
    return extractedQuery
  }

  /**
   * Returns true if the passed object has the specific filter key otherwise this will return false.
   * @param query
   * @param filterKey
   * @returns
   */
  filterExists(query: Dictionary<string | (string | null)[]>, filterKey: string): boolean {
    if (!query) return false
    const rawValue = query[filterKey] as string
    const value = !!rawValue ? rawValue.trim() : rawValue
    return !!value && value != ''
  }

  /**
   * Returns true if the passed object has any filter key otherwise this will return false.
   * @param query
   * @returns
   */
  anyFilterExists(query: Dictionary<string | (string | null)[]>): boolean {
    for (const filterKey in FilterKey) if (this.filterExists(query, filterKey)) return true
    return false
  }

  /**
   * This returns data from the URL query
   * @param route
   * @param filterKey
   * @returns
   */
  getData(query: Dictionary<string | (string | null)[]>, filterKey: FilterKey): IFilterManagerResult {
    const exists = this.filterExists(query, filterKey)
    if (exists) {
      const queryValue = query[filterKey] as string
      const data = queryValue.split(IDNameSeparator)
      const formattedID = data[0]
      const strIDs = formattedID.split(IDSeparator)
      const path = strIDs.map((e) => parseInt(e))
      return { path }
    }
    return { path: [] }
  }

  /**
   * This return data from the cookie
   * @param filterKey
   * @param App
   * @returns
   */
  getDataFromCookie(filterKey: FilterKey, App: any): IFilterManagerResult {
    if (!App) return { path: [] }
    const strQueries = App.state.formattedFilters
    if (strQueries && strQueries != '') {
      const savedQueries = JSON.parse(strQueries)
      return this.getData(savedQueries, filterKey)
    }
    return { path: [] }
  }

  /**
   * Removes a filter from the URL query
   * @param App
   * @param router
   * @param route
   * @param query
   * @param filterKey
   */
  removeFilter(App: any, router: VueRouter, route: Route, filterKey: FilterKey): void {
    const exists = this.filterExists(route.query, filterKey)
    if (exists) {
      const query = { ...route.query }
      delete query[filterKey]
      this.modifyQuery(query, App, router, route)
    }
  }

  /**
   * Fetches the data from the query string
   * @param query
   * @param filterKey
   */
  getQueryValue(query: Dictionary<string | (string | null)[]>, filterKey: FilterKey): string | undefined {
    const data = query[filterKey]
    return data ? data.toString().split(IDNameSeparator)[0] : undefined
  }

  /**
   * Fetches the contract type, if available, from the query string.
   */
  getContractTypeValue(query: Dictionary<string | (string | null)[]>): number | undefined {
    const contractQueryData = query[FilterKey.contract]
    const contractType = contractQueryData ? Number(contractQueryData) : undefined
    return contractType
  }

  /**
   * Converts the saved query string in the cookie to a formatted query string
   * Ex. { return => /?visa=1,3_professor }
   * @param App
   * @returns
   */
  cookieToQueryString(App: any, baseUrl: string): string {
    const strQuery = App.state.formattedFilters
    let url = baseUrl
    if (strQuery && strQuery != '') {
      const query = JSON.parse(strQuery)
      for (const key in FilterKey) {
        const value = query[key]
        if (!value) continue
        if (url === '/' || url === '') url += `?${key}=${value}`
        else url += `&${key}=${value}`
      }
    }
    return url
  }

  /**
   * This extracts data from the passed query that's not a avacancy filter key
   * @param query
   * @returns
   */
  extractExcessDataFromQuery(query: Dictionary<string | (string | null)[]>): Dictionary<string | (string | null)[]> {
    const excessQuery: Dictionary<string | (string | null)[]> = {}
    const filterKeys = Object.values<string>(FilterKey)
    for (const key in query) {
      if (!filterKeys.includes(key)) excessQuery[key] = query[key]
    }
    return excessQuery
  }

  /***
   * Gets the last item in the path
   */
  getBottomItem(path: number[]): number | undefined {
    if (path.length === 0) return undefined
    return path[path.length - 1]
  }
}

/**
 * This contains the id of the root all the way to the target item's id
 */
export interface IFilterManagerResult {
  path: number[]
}
