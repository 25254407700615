
import { defineComponent, PropType, ref, watch } from 'vue'
import CIcon from '../c-icon/c-icon.component.vue'
import { CIconFactory } from '../c-icon/factory/c-icon.factory'
import { IButton } from './interface/i-button'

export default defineComponent({
  name: 'c-button',
  emits: ['click'],
  components: {
    CIcon
  },
  props: {
    /**
     * Contains properties used by the button, as well as the CIcon
     */
    properties: {
      type: Object as PropType<IButton>,
      required: true
    },
    /**
     * Validation classes when the button is validated in parent
     */
    classes: {
      type: Object as PropType<Record<string, boolean>>,
      required: false
    }
  },
  setup(props, { emit }) {
    const currentBtnClass = ref(props.properties.classes)
    const methods = {
      handleMouseOver() {
        if (props.properties.hoverClasses) {
          currentBtnClass.value = props.properties.hoverClasses
        }
      },
      handleMouseOut() {
        if (props.properties.hoverClasses) {
          currentBtnClass.value = props.properties.classes
        }
      },

      /**
       * Emits a signal up to the parent notifying of a click event
       */
      click(): void {
        emit('click')
      },

      /**
       * Perform a router.push if props provided
       * @todo This needs a test for if theres a bad object
       */
      goTo(e: Event): void {
        if (props.properties.link && props.properties.link.router && props.properties.link.link) {
          e.preventDefault()
          props.properties.link.router.push(props.properties.link.link)
        }
      }
    }
    const spinnerIcon = CIconFactory.Spinner()

    watch(
      () => props.properties.classes,
      (newClasses) => {
        currentBtnClass.value = newClasses
      }
    )

    return {
      methods,
      spinnerIcon,
      currentBtnClass
    }
  }
})
