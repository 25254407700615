import { ICandidate } from '../../../../../../core/src/models/candidate/interface/i-candidate'
import { ICity } from '../../../../../../core/src/models/common/location/interface/city/i-city'
import { IPrefecture } from '../../../../../../core/src/models/common/location/interface/prefecture/i-prefecture'
import { JapanId } from '../../../../../../core/src/models/country/constants/japan-constants'
import { CountryEntity } from '../../common/country/entities/country'
import { CountryFactory } from '../../common/country/factories/country.factory'
import { JapaneseConversationEntity } from '../../language/entities/japanese-conversation'
import { JapaneseListeningEntity } from '../../language/entities/japanese-listening'
import { JapaneseReadingEntity } from '../../language/entities/japanese-reading'
import { JapaneseWritingEntity } from '../../language/entities/japanese-writing'
import { JapaneseConversationEntityFactory } from '../../language/factories/japanese-conversation.factory'
import { JapaneseListeningEntityFactory } from '../../language/factories/japanese-listening.factory'
import { JapaneseReadingEntityFactory } from '../../language/factories/japanese-reading.factory'
import { JapaneseWritingEntityFactory } from '../../language/factories/japanese-writing.factory'
import { CandidateVisaEntity, CandidateVisaEntityFactory } from './candidate-visa'
/**
 * Different from a user (who can be an admin, candidate, or agent), a candidate
 * represents a person looking for job opportunities.
 */
export class CandidateEntity {
  private japaneseConversationFactory = new JapaneseConversationEntityFactory()
  private japaneseReadingFactory = new JapaneseReadingEntityFactory()
  private japaneseListeningFactory = new JapaneseListeningEntityFactory()
  private japaneseWritingFactory = new JapaneseWritingEntityFactory()

  private _props: ICandidate
  private _country?: CountryEntity
  private _japaneseConversation: JapaneseConversationEntity
  private _japaneseReading: JapaneseReadingEntity
  private _japaneseListening: JapaneseListeningEntity
  private _japaneseWriting: JapaneseWritingEntity
  private _candidateVisa: CandidateVisaEntity
  private _grewUpInCountry?: CountryEntity

  constructor(props: ICandidate) {
    this._props = props
    this._japaneseReading = this.japaneseReadingFactory.new(this.props.japaneseReading)
    this._japaneseWriting = this.japaneseWritingFactory.new(this.props.japaneseWriting)
    this._japaneseListening = this.japaneseListeningFactory.new(this.props.japaneseListening)
    this._japaneseConversation = this.japaneseConversationFactory.new(this.props.japaneseConversation)
    if (this.props.country) this._country = CountryFactory.new(this.props.country)
    if (this.props.grewUpInCountry) this._grewUpInCountry = CountryFactory.new(this.props.grewUpInCountry)
  }

  get props(): ICandidate {
    return this._props
  }

  get id(): number | undefined {
    return this.props.id
  }

  get userId(): number | undefined {
    return this.props.userId
  }

  get birthDate(): Date | undefined {
    return this.props.birthDate
  }

  set birthDate(value: Date | undefined) {
    this.props.birthDate = value
  }

  /**
   * Visa related candidate data.
   */
  get candidateVisa(): CandidateVisaEntity {
    if (!this._candidateVisa) {
      this._candidateVisa = CandidateVisaEntityFactory.new(this.props.candidateVisa)
    }
    return this._candidateVisa
  }

  set candidateVisa(value: CandidateVisaEntity) {
    this._candidateVisa = value
    this.props.candidateVisa = value.props
  }

  /**
   * The country of citizenship of the candidate.
   */
  get country(): CountryEntity | undefined {
    return this._country
  }

  set country(value: CountryEntity | undefined) {
    this._country = value
    this.props.country = value?.props
    this.props.countryId = value?.props.id
  }

  /**
   * The unique identifier of the country of citizenship of the candidate.
   */
  get countryId(): number | undefined {
    return this.props.countryId
  }

  /**
   * The country where the candidate grew up
   */
  get grewUpInCountry(): CountryEntity | undefined {
    return this._grewUpInCountry
  }

  set grewUpInCountry(value: CountryEntity | undefined) {
    this._grewUpInCountry = value
    this.props.grewUpInCountry = value?.props
    this.props.grewUpInCountryId = value?.props.id
  }

  /**
   * The unique identifier of the country when the candidate grew up
   */
  get grewUpInCountryId(): number | undefined {
    return this.props.grewUpInCountryId
  }

  get firstArrivalDate(): Date | undefined {
    return this.props.firstArrivalDate
  }

  set firstArrivalDate(value: Date | undefined) {
    this.props.firstArrivalDate = value
  }

  /**
   * The unique identifier of the country when the candidate grew up
   */

  get isJapaneseCitizen(): boolean {
    return this.props.country?.id === JapanId
  }

  get isJapaneseCitizenAndNotGrewUpInJapan(): boolean {
    return this.isJapaneseCitizen && this.props.grewUpInCountry?.id !== JapanId && !!this.props.grewUpInCountry?.id
  }
  /**
   * Returns true if the user/candidate is done with the welcome step of the onboarding
   */
  get hasPassedOnboardingWelcome(): boolean {
    if (this.props.hasPassedOnboardingWelcome === undefined) {
      this.props.hasPassedOnboardingWelcome = false
    }
    return this.props.hasPassedOnboardingWelcome
  }

  set hasPassedOnboardingWelcome(value: boolean) {
    this._props.hasPassedOnboardingWelcome = value
  }

  /**
   * Returns true if the user/candidate is done with the name step of the onboarding
   */
  get hasPassedOnboardingName(): boolean {
    if (this.props.hasPassedOnboardingName === undefined) {
      this.props.hasPassedOnboardingName = false
    }
    return this.props.hasPassedOnboardingName
  }

  set hasPassedOnboardingName(value: boolean) {
    this._props.hasPassedOnboardingName = value
  }

  /**
   * Returns true if the user/candidate is done with the name step of the onboarding
   */
  get hasFinishedOnboarding(): boolean {
    if (this.props.hasFinishedOnboarding === undefined) {
      this.props.hasFinishedOnboarding = false
    }
    return this.props.hasFinishedOnboarding
  }

  set hasFinishedOnboarding(value: boolean) {
    this._props.hasFinishedOnboarding = value
  }

  /**
   * Whether the candidate accepted to show their residence card during interview
   */
  get residenceCardRequirementAccepted(): boolean {
    if (this.props.residenceCardRequirementAccepted === undefined) {
      this.props.residenceCardRequirementAccepted = false
    }
    return this.props.residenceCardRequirementAccepted
  }

  set residenceCardRequirementAccepted(value: boolean) {
    this._props.hasFinishedOnboarding = value
  }

  /**
   * Current Japanese Conversation skill.
   */
  get japaneseConversation(): JapaneseConversationEntity {
    return this._japaneseConversation
  }

  set japaneseConversation(value: JapaneseConversationEntity) {
    this._japaneseConversation = value
    this._props.japaneseConversation = value.props
  }

  /**
   * Current Japanese Reading skill.
   */
  get japaneseReading(): JapaneseReadingEntity {
    return this._japaneseReading
  }

  set japaneseReading(value: JapaneseReadingEntity) {
    this._japaneseReading = value
    this._props.japaneseReading = value.props
  }

  /**
   * Current Japanese Writing skill.
   */
  get japaneseWriting(): JapaneseWritingEntity {
    return this._japaneseWriting
  }

  set japaneseWriting(value: JapaneseWritingEntity) {
    this._japaneseWriting = value
    this._props.japaneseWriting = value.props
  }

  /**
   * Current Japanese Listening skill.
   */
  get japaneseListening(): JapaneseListeningEntity {
    return this._japaneseListening
  }

  set japaneseListening(value: JapaneseListeningEntity) {
    this._japaneseListening = value
    this._props.japaneseListening = value.props
  }

  /**
   * Current prefecture where the candidate lives
   */
  get prefecture(): IPrefecture | undefined {
    return this.props.prefecture
  }

  set prefecture(value: IPrefecture | undefined) {
    this.props.prefecture = value
    this.props.prefectureId = value?.id
  }

  /**
   * Current city where the candidate lives
   */
  get city(): ICity | undefined {
    return this.props.city
  }

  set city(value: ICity | undefined) {
    this.props.city = value
    this.props.cityId = value?.id
  }


}
