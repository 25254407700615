import { IconType } from '../enum/icon-type.enum'
import { Icon } from '../class/icon'
import { IconFlip } from '../enum/icon-flip.enum'
import { IconPlacement } from '../enum/icon-placement.enum'
import { IconSize } from '../enum/icon-size.enum'
import { StaticFileUrls } from '../../../../../../core/src'
import { IIcon } from '../interfaces/i-icon'

/**
 * Creates differnt types of icons.
 */
export class CIconFactory {
  /**
   * Create Icons
   * @returns Icon
   */
  static Icon(props: IIcon): IIcon {
    return {
      icon: 'circle-exclamation',
      classes: '',
      ...props
    }
  }
  static SmallIcon(props: IIcon): IIcon {
    const smallIcon = CIconFactory.Icon({
      size: IconSize.small,
      ...props
    })
    return smallIcon
  }
  static MediumIcon(props: IIcon): IIcon {
    const mediumIcon = CIconFactory.Icon({
      size: IconSize.medium,
      ...props
    })
    return mediumIcon
  }
  static LargeIcon(props: IIcon): IIcon {
    const largeIcon = CIconFactory.Icon({
      size: IconSize.large,
      ...props
    })
    return largeIcon
  }
  static Globe(): IIcon {
    return CIconFactory.Icon({
      icon: 'globe',
      size: IconSize.medium,
      type: IconType.light,
      classes: 'color-blue-dark1'
    })
  }
  static Asterisk(): IIcon {
    return CIconFactory.Icon({
      icon: 'asterisk',
      size: IconSize.small,
      classes: 'color-red-medium1'
    })
  }
  static DropdownCaret(): IIcon {
    return CIconFactory.Icon({
      icon: 'caret-down',
      placement: IconPlacement.Right
    })
  }
  static ModalCancel(): IIcon {
    return CIconFactory.Icon({
      icon: 'circle-xmark',
      size: IconSize.small,
      placement: IconPlacement.Left
    })
  }
  static ModalDelete(): IIcon {
    return CIconFactory.Icon({
      icon: 'trash-can',
      size: IconSize.small,
      placement: IconPlacement.Left
    })
  }
  static ModalSave(): IIcon {
    return CIconFactory.Icon({
      icon: 'circle-check',
      size: IconSize.small,
      placement: IconPlacement.Left
    })
  }
  static CircleCheckMd(): IIcon {
    return CIconFactory.Icon({
      icon: 'circle-check',
      size: IconSize.medium,
    })
  }
  static CircleXmarkLeft(): IIcon {
    return CIconFactory.Icon({
      icon: 'circle-xmark',
      placement: IconPlacement.Left
    })
  }
  static RightChevron(): IIcon {
    return CIconFactory.Icon({
      icon: 'chevron-right',
      type: IconType.thin,
      classes: 'color-text-medium1'
    })
  }
  static Translation(props?: IIcon): IIcon {
    return CIconFactory.Icon({
      icon: 'language',
      ...props
    })
  }
  static helperID(): IIcon {
    return CIconFactory.Icon({
      icon: 'id-card',
      flip: IconFlip.Horizontal,
      placement: IconPlacement.Right,
      classes: 'color-text-medium1'
    })
  }
  static Brand(props: IIcon): IIcon {
    const Brand = CIconFactory.Icon({
      type: IconType.brand,
      icon: props.icon,
      placement: IconPlacement.Left,
      ...props
    })
    return Brand
  }

  static Spinner(props?: IIcon): IIcon {
    const Spinner = CIconFactory.Icon({
      icon: 'spinner-third',
      type: IconType.duotone,
      classes: 'spinner',
      ...props
    })
    return Spinner
  }

  static Apple(props?: IIcon): IIcon {
    const Apple = CIconFactory.Brand({
      icon: 'apple',
      ...props
    })
    return Apple
  }

  static Preview(props?: IIcon): IIcon {
    const Preview = CIconFactory.Brand({
      icon: 'file-magnifying-glass',
      type: IconType.regular,
      classes: '',
      ...props
    })
    return Preview
  }

  /**
   * ------------ SOCIAL ICONS / BRANDS -------------
   */

  static Facebook(props?: IIcon): IIcon {
    const Facebook = CIconFactory.Brand({
      icon: 'facebook',
      size: IconSize.large,
      ...props
    })
    return Facebook
  }

  static Messenger(props?: IIcon): IIcon {
    const Twitter = CIconFactory.Brand({
      icon: 'facebook-messenger',
      size: IconSize.large,
      ...props
    })
    return Twitter
  }

  static Twitter(props?: IIcon): IIcon {
    const Twitter = CIconFactory.Brand({
      icon: 'twitter',
      size: IconSize.large,
      ...props
    })
    return Twitter
  }

  static Line(props?: IIcon): IIcon {
    const Line = CIconFactory.Brand({
      icon: 'line',
      size: IconSize.large,
      ...props
    })
    return Line
  }

  static Viber(props?: IIcon): IIcon {
    const Viber = CIconFactory.Brand({
      icon: 'viber',
      size: IconSize.large,
      ...props
    })
    return Viber
  }

  static Email(props?: IIcon): IIcon {
    const Email = CIconFactory.Brand({
      icon: 'envelope',
      type: IconType.solid,
      size: IconSize.large,
      ...props
    })
    return Email
  }

  static SMS(props?: IIcon): IIcon {
    const Twitter = CIconFactory.Icon({
      icon: 'message-sms',
      size: IconSize.large,
      ...props
    })
    return Twitter
  }

  static LinkedIn(props?: IIcon): IIcon {
    const LinkedIn = CIconFactory.Brand({
      icon: 'linkedin',
      size: IconSize.large,
      ...props
    })
    return LinkedIn
  }

  static Kakao(props?: IIcon): IIcon {
    const Kakao = CIconFactory.Icon({
      svg: StaticFileUrls.kakaoIcon,
      size: IconSize.large,
      ...props
    })
    return Kakao
  }

  static Zalo(props?: IIcon): IIcon {
    const Zalo = CIconFactory.Icon({
      svg: StaticFileUrls.zaloIcon,
      size: IconSize.large,
      ...props
    })
    return Zalo
  }

  // Agent logged in Error Ribbon

  static Exclamation(props?: IIcon): IIcon {
    const Exclamation = CIconFactory.Icon({
      icon: 'circle-exclamation',
      size: IconSize.xlarge,
      classes: 'color-gold-dark1',
      ...props
    })
    return Exclamation
  }
}
