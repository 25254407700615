import { IUser } from '../../user/interface/i-user';
import { CandidateJapaneseLanguageSkillsDto } from '../dto/candidate-japanese-language-skills.dto';

export class CandidateJapaneseLanguageSkillsDtoFactory {
  new(user: IUser): CandidateJapaneseLanguageSkillsDto {
    const japaneseListeningId = user.candidate?.japaneseListening?.id || user.candidate?.japaneseListeningId;
    if (!japaneseListeningId) throw new Error('Expected a non-zero japanese listening id');
    const japaneseConversationId = user.candidate?.japaneseConversation?.id || user.candidate?.japaneseConversationId;
    const japaneseReadingId = user.candidate?.japaneseReading?.id || user.candidate?.japaneseReadingId;
    const japaneseWritingId = user.candidate?.japaneseWriting?.id || user.candidate?.japaneseWritingId;
    const dto = new CandidateJapaneseLanguageSkillsDto();
    dto.japaneseListeningId = japaneseListeningId;
    dto.japaneseConversationId = japaneseConversationId;
    dto.japaneseReadingId = japaneseReadingId;
    dto.japaneseWritingId = japaneseWritingId;
    return dto;
  }
}
