const middleware = {}

middleware['admin-auth'] = require('../app/middleware/admin-auth.ts')
middleware['admin-auth'] = middleware['admin-auth'].default || middleware['admin-auth']

middleware['authenticated-optional'] = require('../app/middleware/authenticated-optional.ts')
middleware['authenticated-optional'] = middleware['authenticated-optional'].default || middleware['authenticated-optional']

middleware['authenticated'] = require('../app/middleware/authenticated.ts')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['server-rendering-restrictor'] = require('../app/middleware/server-rendering-restrictor.ts')
middleware['server-rendering-restrictor'] = middleware['server-rendering-restrictor'].default || middleware['server-rendering-restrictor']

middleware['vacancy.details.authenticated'] = require('../app/middleware/vacancy.details.authenticated.ts')
middleware['vacancy.details.authenticated'] = middleware['vacancy.details.authenticated'].default || middleware['vacancy.details.authenticated']

export default middleware
