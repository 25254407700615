import { IJapaneseListening } from '../../../../../../core/src'
import { JapaneseListeningEntity } from '../entities/japanese-listening'

export class JapaneseListeningEntityFactory {
  /**
   * Generates an IJapaneseListening object. Used to add extra properties during the conversion process.
   * @param props Data to use when creating the object.
   */
  defaultProps(props?: IJapaneseListening): IJapaneseListening {
    return { id: 0, i18n: [], ...props }
  }

  /**
   * Generates a JapaneseListeningEntity object.
   * @param props Data to use when creating the object.
   */
  new(props?: IJapaneseListening): JapaneseListeningEntity {
    return new JapaneseListeningEntity(this.defaultProps(props))
  }

  /**
   * Shorthand version to create an entity containing only a value and a label.
   * @param value
   * @param label
   * @returns
   */
  newFromValueLabel(value: number, label: string): JapaneseListeningEntity {
    return new JapaneseListeningEntity(
      this.defaultProps({
        id: value,
        i18n: [{ name: label, description: '', locale: '' }],
      })
    )
  }
}
