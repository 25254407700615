import GetMeUseCase from '@/modules/user/usecases/me.usecase'
import { Middleware } from '@nuxt/types'
import { Context } from '@nuxt/types/app'
import { LogType } from '@tokyojob/frontend-core'

const vacancyDetailsAuthenticated: Middleware = async ({ App, redirect }: Context) => {
  try {
    if (App.state.token) {
      const me = new GetMeUseCase(App)
      await me.execute()
    }
  } catch (error) {
    App.logService.handle({ type: LogType.Error, error: error as Error })
  }
}

export default vacancyDetailsAuthenticated
