import { CandidateSex, ICandidate, IJPLevel, LanguageLocale } from '../../../models';
import { DateFormatter } from '../../../utils';
import { CandidateVisaGetters } from '../../candidate-visa/getters/candidate-visa.getters';

/**
 * This class is responsible for getting / constructing candidate data.
 */
export class CandidateGetters {
  /**
   * Returns the last name of the candidate
   * @param candidate
   * @returns
   */
  static getLastName(candidate: ICandidate): string {
    if (!candidate || !candidate.user) return '';
    return candidate.user.lastName!;
  }
  /**
   * Returns the first name of the candidate
   * @param candidate
   * @returns
   */
  static getFirstName(candidate: ICandidate): string {
    if (!candidate || !candidate.user) return '';
    return candidate.user.firstName!;
  }
  /**
   * Return the full name of the candidate
   * @param candidate
   * @returns
   */
  static getFullName(candidate: ICandidate): string {
    if (!candidate || !candidate.user) return '';
    return `${candidate.user.lastName} ${candidate.user.firstName}`;
  }

  /**
   * Returns the nick name of the candidate
   * @param candidate
   * @returns
   */
  static getNickName(candidate: ICandidate): string {
    if (!candidate || !candidate.user) return '';
    return candidate.user.nickname!;
  }

  /**
   * Returns the gender of the candidate
   * @param candidate
   * @returns
   */
  static getGender(candidate: ICandidate, locale: LanguageLocale): string {
    if (!candidate || !candidate.user) return '';
    if(locale === LanguageLocale.JAjp) return candidate.user.gender === CandidateSex.Male ? '男性' : '女性'
    return `${candidate.user.gender}`;
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getBirthDate(candidate: ICandidate): string {
    if (!candidate) return '';
    const formatter = new DateFormatter();
    // Had to do this due to how data comes as fro mthe db.
    return `${formatter.getYYYYMMDDString(candidate.birthDate)}`;
  }

  /**
   * This returns the country name data of the candidate.
   * @param candidate
   * @returns
   */
  static getCountryName(candidate: ICandidate): string {
    const hasValidCountryData =
      !!candidate && !!candidate.country && !!candidate.country.i18n && candidate.country.i18n.length > 0;
    if (!hasValidCountryData) return '';
    const name = candidate.country!.i18n![0].name!;
    return name;
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getArrivalDate(candidate: ICandidate): string {
    if (!candidate) return '';
    const formatter = new DateFormatter();
    // Had to do this due to how data comes as fro mthe db.
    return `${formatter.getYYYYMMDDString(candidate.firstArrivalDate)}`;
  }

  /**
   * Returns the prefecture of the candidate
   * @param candidate
   * @returns
   */
  static getPrefecture(candidate: ICandidate): string {
    if (!candidate) return '';
    if (!candidate.prefecture || !candidate.prefecture.i18n || candidate.prefecture.i18n.length === 0) return '';
    return candidate.prefecture.i18n[0].name!;
  }

  /**
   * Returns the city of the candidate
   * @param candidate
   * @returns
   */
  static getCity(candidate: ICandidate): string {
    if (!candidate) return '';
    if (!candidate.city || !candidate.city.i18n || candidate.city.i18n.length === 0) return '';
    return candidate.city.i18n[0].name!;
  }

  /**
   * This returns a formatted visa type name data of a candidate.
   * @param candidate
   * @returns
   */
  static getFormattedVisaName(candidate: ICandidate): string {
    const hasValidVisaData = candidate && candidate.candidateVisa;
    if (!hasValidVisaData) return '';
    return CandidateVisaGetters.getFormattedVisaName(candidate.candidateVisa!);
  }

  /**
   * This returns the japanese language information of a candidatee.
   * @param candidate 
   * @returns 
   */
  static getJapaneseLevels(candidate: ICandidate): IJPLevel {
    if (!candidate) {
      return {
        japaneseConversations: {},
        japaneseListenings: {},
        japaneseReadings: {},
        japaneseWritings: {},
      };
    }
    return {
      japaneseConversations: candidate.japaneseConversation!,
      japaneseListenings: candidate.japaneseListening!,
      japaneseReadings: candidate.japaneseReading!,
      japaneseWritings: candidate.japaneseWriting!,
    };
  }
}
