import { GatewayBase } from '@/base/gateways/base'
import { IVacancy, IVacancyDetails, IVacancyMapPoint, LanguageLocale, VacancyMapQueryDto } from '@tokyojob/core'
import { IPaginateProps, QueryStringEntity } from '@tokyojob/frontend-core'
import {
  GetByApplicationId,
  GetPreviewRequest,
  GetRequest,
  GetVacancyMapRequest,
  GetWithLoggedInCandidateRequest,
  PaginateRequest
} from '../network/requests/vacancy'

export default class Vacancy extends GatewayBase {
  async Paginate(sessionId: string, isSSRRequest: boolean, entity: QueryStringEntity): Promise<IPaginateProps<IVacancy>> {
    const { rows, ...data } = await this.apiClient.request(new PaginateRequest(sessionId, isSSRRequest, entity))
    return {
      ...data,
      rows: rows
    }
  }

  async Get(id: string, visaTypeId?: number, locale?: LanguageLocale): Promise<IVacancyDetails | null> {
    const item = await this.apiClient.request(new GetRequest(id, visaTypeId, locale))
    return item
  }

  async GetPreview(id: string, locale: LanguageLocale): Promise<IVacancyDetails | null> {
    const item = await this.apiClient.request(new GetPreviewRequest(id, locale))
    return item
  }

  async GetByApplicationId(applicationId: string): Promise<IVacancy | null> {
    const item = await this.apiClient.request(new GetByApplicationId(applicationId))
    return item ? item : null
  }

  async GetVacancyMap(dto: VacancyMapQueryDto, entity: QueryStringEntity): Promise<IVacancyMapPoint[]> {
    return this.apiClient.request(new GetVacancyMapRequest(dto, entity))
  }

  /**
   * Obtains a vacancy's public information, plus any data related to it and the currently logged in candidate.
   */
  async GetWithLoggedInCandidate(id: string, locale: LanguageLocale = LanguageLocale.USen): Promise<IVacancyDetails | null> {
    const item = await this.apiClient.request(new GetWithLoggedInCandidateRequest(id, locale))
    return item
  }
}
