
import { defineComponent, PropType, computed } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Icon } from './class/icon'

export default defineComponent({
  name: 'c-icon',
  components: {
    FontAwesomeIcon
  },
  props: {
    /**
     * Object containing all item properties.
     */
    icon: {
      type: Object as PropType<Icon>,
      required: true
    }
  },
  setup(props) {
    const currentIcon = computed((): string | undefined => {
      return props.icon.icon
    })
    const currentClasses = computed((): string => {
      return props.icon.classes!
    })
    return {
      currentIcon,
      currentClasses
    }
  }
})
