import { IFilterableDataSetItem } from "../../../../../../../core/src/models/common/filter/i-filterable-data-set-item"
import { LocalizationPath } from "../../../../../../../core/src/models/common/localization/constants/localization-path"
import { LocalizationData } from "../../../../../../../core/src/models/common/localization/localization-data"
import { FilterKey } from "../../../../../../../core/src/models/vacancy/enum/filter-key.enum"
import { LocalizationService } from "../../../../../../core/src"
import { IFilterTag } from "../interface/i-filter-tag"

/**
 * Creates tags that can be used together with the filters component.
 */
export class FilterTagFactory {
  public readonly visaTypeFilterTheme: string = 'theme-purple-pastel-light-color'
  public readonly categoryFilterTheme: string = 'theme-green-pastel-light-color'
  public readonly locationFilterTheme: string = 'theme-blue-pastel-light-color'
  public readonly multiFilterTheme: string = 'theme-shades-pastel-light-color'

  /**
   * The mapping of FilterKey and the default object value/structure of IFilterTag
   */
  private readonly filterTagBlueprintMap: Record<string, IFilterTag> = {
    [FilterKey.visa]: {
      itemCategoryName: 'Visa',
      itemName: '',
      classes: this.visaTypeFilterTheme
    },
    [FilterKey.location]: {
      itemCategoryName: 'Location',
      itemName: '',
      classes: this.locationFilterTheme
    },
    [FilterKey.category]: {
      itemCategoryName: 'Category',
      itemName: '',
      classes: this.categoryFilterTheme
    },
    [FilterKey.contract]: {
      itemCategoryName: 'Contract',
      itemName: '',
      classes: this.multiFilterTheme
    }
  }

  /**
   * Converts the passed data into an instance of IFilterTag
   * @param itemName
   * @param filterKey
   * @returns
   */
  toTag(localization: LocalizationData, item: IFilterableDataSetItem, filterKey: FilterKey): IFilterTag {
    const label = this.getTagLabel(localization, filterKey)
    return {
      ...this.filterTagBlueprintMap[filterKey],
      itemName: item.name,
      item,
      filterKey: filterKey as FilterKey,
      label
    }
  }

  /**
   *
   * @param filterKey
   * @returns
   */
  getTagLabel(localization: LocalizationData, filterKey: FilterKey): string {
    // TODO: Had to do it like this for now.
    // We were using a getLabel function in the IFilterTag interface, but it caused issues in SSR.
    switch (filterKey) {
      case FilterKey.visa:
        return LocalizationService.t<string>(localization, LocalizationPath.visa)
      case FilterKey.location:
        return LocalizationService.t<string>(localization, LocalizationPath.locationFilterKeys)
      case FilterKey.category:
        return LocalizationService.t<string>(localization, LocalizationPath.category)
    }
    return LocalizationService.t<string>(localization, LocalizationPath.contract)
  }
}
