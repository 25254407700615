import { CandidateSex, IApplicationCandidate, IJPLevel, LanguageLocale } from '../../../models';
import { DateFormatter } from '../../../utils';

/**
 * This class is responsible for getting / constructing candidate data.
 */
export class ApplicationCandidateGetters {
  /**
   * Returns the last name of the candidate
   * @param candidate
   * @returns
   */
  static getLastName(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    return candidate.lastName!;
  }

  /**
   * Returns the first name of the candidate
   * @param candidate
   * @returns
   */
  static getFirstName(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    return candidate.firstName!;
  }

  /**
   * Returns the nick name of the candidate
   * @param candidate
   * @returns
   */
  static getNickName(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    return candidate.nickname!;
  }

  /**
   * Returns the full name of the candidate
   * @param candidate
   * @returns
   */
  static getFullName(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    return `${candidate.lastName} ${candidate.firstName}`;
  }

  /**
   * Returns the gender of the candidate
   * @param candidate
   * @returns
   */
  static getGender(candidate: IApplicationCandidate, locale: LanguageLocale): string {
    if (!candidate) return '';
    if(locale === LanguageLocale.JAjp) return candidate.gender === CandidateSex.Male ? '男性' : '女性'
    return `${candidate.gender}`;
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getBirthDate(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    const formatter = new DateFormatter();
    // Had to do this due to how data comes as fro mthe db.
    return `${formatter.getYYYYMMDDString(candidate.birthDate)}`;
  }

  /**
   * Returns the country of the candidate
   * @param candidate
   * @returns
   */
  static getCountry(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    if (!candidate.country || !candidate.country.i18n || candidate.country.i18n.length === 0) return '';
    return candidate.country.i18n[0].name!;
  }

  /**
   * Returns the birth date of the candidate
   * @param candidate
   * @returns
   */
  static getArrivalDate(candidate: IApplicationCandidate): string | undefined {
    if (!candidate) return '';
    const formatter = new DateFormatter();
    // Had to do this due to how data comes as fro mthe db.
    const date = formatter.getYYYYMMDDString(candidate.firstArrivalDate)
    if (!date) return undefined
    return date;
  }

  /**
   * Returns the prefecture of the candidate
   * @param candidate
   * @returns
   */
  static getPrefecture(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    if (!candidate.prefecture || !candidate.prefecture.i18n || candidate.prefecture.i18n.length === 0) return '';
    return candidate.prefecture.i18n[0].name!;
  }

  /**
   * Returns the city of the candidate
   * @param candidate
   * @returns
   */
  static getCity(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    if (!candidate.city || !candidate.city.i18n || candidate.city.i18n.length === 0) return '';
    return candidate.city.i18n[0].name!;
  }

  /**
   * Returns the formatted visa type name of the candidate
   * @param candidate
   * @returns
   */
  static getFormattedVisaName(candidate: IApplicationCandidate): string {
    if (!candidate) return '';
    const { visaType, visaSubType } = candidate;
    const hasValidVisaTypeData = !!visaType && !!visaType.i18n && visaType.i18n.length > 0;
    if (!hasValidVisaTypeData) return '';
    let str = `${visaType!.i18n![0].name}`;
    const hasValidVisaSubtype = !!visaSubType && !!visaSubType.i18n && visaSubType.i18n.length > 0;
    if (hasValidVisaSubtype) str = `${str} ${visaSubType!.i18n![0].name}`
    return str;
  }

  /**
   * This returns the japanese language information of a candidate.
   * @param candidate
   * @returns
   */
  static getJapaneseLevels(candidate: IApplicationCandidate): IJPLevel {
    if (!candidate) {
      return {
        japaneseConversations: {},
        japaneseListenings: {},
        japaneseReadings: {},
        japaneseWritings: {},
      };
    }
    return {
      japaneseConversations: candidate.japaneseConversation!,
      japaneseListenings: candidate.japaneseListening!,
      japaneseReadings: candidate.japaneseReading!,
      japaneseWritings: candidate.japaneseWriting!,
    };
  }
}
