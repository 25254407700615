import { LanguageLocale, VacancyMapQueryDto } from '@tokyojob/core'
import { APIRequest, HTTPMethod, QueryStringEntity } from '@tokyojob/frontend-core'
import { SpaceVacancy } from '../schema/namespace'

export class GetRequest implements APIRequest<SpaceVacancy.GetIVacancyDetailsResponse> {
  response!: SpaceVacancy.GetIVacancyDetailsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: string, visaTypeId?: number, locale?: LanguageLocale) {
    let query = ``
    if (visaTypeId) query = this.addQuery(query, 'visaTypeId', visaTypeId.toString())
    if (locale) query = this.addQuery(query, 'locale', locale)
    this.path = `/vacancy/public/${id}${query}`
  }

  addQuery(currentQuery: string, key: string, value: string): string {
    if (currentQuery) return `${currentQuery}&${key}=${value}`
    return `?${key}=${value}`
  }
}

export class GetPreviewRequest implements APIRequest<SpaceVacancy.GetIVacancyDetailsResponse> {
  response!: SpaceVacancy.GetIVacancyDetailsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: string, locale: LanguageLocale) {
    this.path = `/vacancy/public/preview/${id}?locale=${locale}`
  }
}

export class GetVacancyMapRequest implements APIRequest<SpaceVacancy.GetVacancyMapResponse> {
  response!: SpaceVacancy.GetVacancyMapResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(dto: VacancyMapQueryDto, entity: QueryStringEntity) {
    const { locale, decimalPrecision, lowerLeftLat, lowerLeftLng, upperRightLat, upperRightLng, sessionId, isSSRRequest } = dto
    const localeStr = `locale=${locale}`
    const lowerLeftStr = `&lowerLeftLat=${lowerLeftLat}&lowerLeftLng=${lowerLeftLng}`
    const upperRightStr = `&upperRightLat=${upperRightLat}&upperRightLng=${upperRightLng}`
    const precision = `&decimalPrecision=${decimalPrecision}`
    const isSSRRequestStr = `&isSSRRequest=${isSSRRequest}`
    let queryParams = `${localeStr}${lowerLeftStr}${upperRightStr}${precision}${isSSRRequestStr}`
    if (sessionId) queryParams += `&sessionId=${sessionId}`
    this.path = `/vacancy/public/map/${entity.queries}&${queryParams}`
  }
}

/**
 * Obtains a vacancy's public information, plus any data related to it and the currently logged in candidate.
 */
export class GetWithLoggedInCandidateRequest implements APIRequest<SpaceVacancy.GetIVacancyDetailsResponse> {
  response!: SpaceVacancy.GetIVacancyDetailsResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: string, locale: LanguageLocale) {
    this.path = `/vacancy/public/vacancy.candidate/${id}?locale=${locale}`
  }
}

export class PaginateRequest implements APIRequest<SpaceVacancy.PaginateResponse> {
  response!: SpaceVacancy.PaginateResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(sessionId: string, isSSRRequest: boolean, entity: QueryStringEntity) {
    if (sessionId) this.path = `/vacancy/public/${entity.queries}&sessionId=${sessionId}&isSSRRequest=${isSSRRequest}`
    else this.path = `/vacancy/public/${entity.queries}&isSSRRequest=${isSSRRequest}`
  }
}

/**
 * Obtains a vacancy's information using the application id
 */
export class GetByApplicationId implements APIRequest<SpaceVacancy.GetResponse> {
  response!: SpaceVacancy.GetResponse
  path: string
  contentType = 'application/json'
  method = HTTPMethod.GET
  constructor(id: string) {
    this.path = `/vacancy/application/${id}`
  }
}
