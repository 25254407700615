import { IDNameSeparator, IDSeparator } from '../../../../../../core/src'
import { IGenericQueryStringProps, IQueryStringProps } from './../interfaces/'
import { ICustomQueryStringData } from '../interfaces/options'

/**
 * This entity class contains the data regarding the query string of the current URL.
 * @todo Consider deleting this entity altogether.
 */
export class QueryStringEntity {
  private _props: IQueryStringProps

  constructor(props: IQueryStringProps) {
    this._props = props
  }

  get props(): IQueryStringProps {
    return this._props
  }

  get page(): string {
    return this.props.page || ''
  }

  set page(value: string) {
    this.props.page = value
  }

  get locale(): string {
    return this.props.locale || ''
  }

  set locale(value: string) {
    this.props.locale = value
  }

  get q(): string {
    return this.props.q || ''
  }

  set q(value: string) {
    this.props.q = value
  }

  get clientId(): string {
    return this.props.clientId || ''
  }

  set clientId(value: string) {
    this.props.clientId = value
  }

  get workplaceId(): string {
    return this.props.workplaceId || ''
  }

  set workplaceId(value: string) {
    this.props.workplaceId = value
  }

  get title(): string {
    return this.props.title || ''
  }

  set title(value: string) {
    this.props.title = value
  }

  get visa(): string {
    return this.props.visa || ''
  }

  set visa(value: string) {
    this.props.visa = value
  }

  get category(): string {
    return this.props.category || ''
  }

  set category(value: string) {
    this.props.category = value
  }

  /**
   * The location where the vacancy should be in.
   */
  get location(): string {
    return this.props.location || ''
  }

  set location(value: string) {
    this.props.location = value
  }

  get contract(): string {
    return this.props.contract || ''
  }

  set contract(value: string) {
    this.props.contract = value
  }

  get visaTypeId(): string {
    if (!this.visa) return ''
    const formattedId = this.visa.split(IDNameSeparator)
    const ids = formattedId[0].split(IDSeparator)
    return ids[ids.length - 1]
  }

  get industryId(): string {
    if (!this.category) return ''
    const formattedId = this.category.split(IDNameSeparator)
    const ids = formattedId[0].split(IDSeparator)
    return ids[0]
  }

  get jobSectorId(): string {
    if (!this.category) return ''
    const formattedId = this.category.split(IDNameSeparator)
    const ids = formattedId[0].split(IDSeparator)
    return ids.length > 1 ? ids[1] : ''
  }

  get prefectureId(): string {
    if (!this.location || this.location === '') return ''
    const formattedId = this.location.split(IDNameSeparator)
    const ids = formattedId[0].split(IDSeparator)
    // Index 0 is the Region, but it's never used in our queries.
    return ids.length > 1 ? ids[1] : ''
  }

  get cityId(): string {
    if (!this.location || this.location === '') return ''
    const formattedId = this.location.split(IDNameSeparator)
    const ids = formattedId[0].split(IDSeparator)
    // Index 0 is the Region, but it's never used in our queries.
    return ids.length > 2 ? ids[2] : ''
  }

  get contractTypeId(): string {
    return this.contract
  }

  get queries(): string {
    const queries: any = {
      q: this.q,
      page: this.page,
      clientId: this.clientId,
      workplaceId: this.workplaceId,
      locale: this.locale,
      title: this.title,
      visaTypeId: this.visaTypeId,
      industryId: this.industryId,
      jobSectorId: this.jobSectorId,
      prefectureId: this.prefectureId,
      cityId: this.cityId,
      contractTypeId: this.contractTypeId,
    }
    const queryString = Object.keys(queries)
      .filter((key) => queries[key] !== '')
      .map((key) => key + '=' + queries[key])
      .join('&')

    return '?' + queryString
  }
}


export class GenericQueryStringEntity<T extends ICustomQueryStringData> {
  private _props: IGenericQueryStringProps & T

  constructor(props: IGenericQueryStringProps & T) {
    this._props = props
  }

  get props(): IGenericQueryStringProps & T {
    return this._props
  }

  get page(): string {
    return this.props.page || ''
  }

  set page(value: string) {
    this.props.page = value
  }

  get locale(): string {
    return this.props.locale || ''
  }

  set locale(value: string) {
    this.props.locale = value
  }

  get queries(): string {
    const queries: any = {
      page: this.page,
      locale: this.locale,
      ...this.props, // Include custom properties
    }

    const queryString = Object.keys(queries)
      .filter((key) => queries[key] !== '')
      .map((key) => key + '=' + queries[key])
      .join('&')

    return '?' + queryString
  }
}
